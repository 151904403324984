import React from "react";
import Spline from "@splinetool/react-spline";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

// Global styles to reset margins and prevent white space
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  body {
    font-family: 'Spline Sans Mono', monospace;
  }
`;

const Logo = styled.div`
  position: absolute;
  bottom: 23px;
  right: 18px;
  z-index: 1000;
  background-color: #000000;
  color: white;
  border-radius: 30%;
  width: 150px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.9);
  cursor: pointer;
  text-transform: uppercase;
`;

const Navbar = styled.nav`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  width: 90%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
  z-index: 10;

  @media (min-width: 1200px) {
    position: absolute;
    top: 5%;
    left: 50%;
    border-radius: 30px;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
    z-index: 10;
  }
`;

const NavItems = styled.ul`
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;

  @media (max-width: 900px) {
    gap: 1px;
  }
`;

const NavItem = styled.li`
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 8px; /* Add padding for spacing */

  &:hover::after {
    content: "";
    position: absolute;
    top: 0; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px; /* Rounded corners */
    background: rgba(255, 255, 255, 0.2); /* Transparent background */
    z-index: -1; /* Place behind the text */
  }

  /* Existing media queries */
  @media (max-width: 900px) {
    font-size: 0.8rem;
  }

  @media (max-width: 600px) {
    font-size: 0.6rem;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const StyledLink = styled(RouterLink)`
  color: white;
  text-decoration: none;
  }
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
`;

const Title = styled.h1`
  color: white;
  font-size: 1.5rem;
  margin: 0;
  font-family: "Spline Sans Mono", monospace;

  @media (max-width: 900px) {
    font-size: 0.9rem;
  }

  @media (max-width: 600px) {
    font-size: 0.6rem;
  }
`;

const TitleLink = styled(RouterLink)`
  color: white;
  font-size: 1.5rem;
  @media (max-width: 1350px) {
    font-size: 1.2rem;
  }
  margin: 0;
  text-decoration: none;
  font-family: "Spline Sans Mono", monospace;

  &:hover {
    color: #9400a6;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    font-size: 0.7rem;
  }
`;

export default function App() {
  return (
    <Router>
      <GlobalStyle />
      <Navbar>
        <Title>
          <TitleLink to="/">Gabriel Holder - Software Developer</TitleLink>
        </Title>
        <NavItems>
          <NavItem>
            <StyledLink to="/">Home</StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink to="/boeing">Boeing</StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink to="/nttdata">NTTDATA</StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink to="/uta">UTA</StyledLink>
          </NavItem>
          <NavItem>
            <Link href="https://codekids.cs.vt.edu/about_us" target="_blank">
              University Study
            </Link>
          </NavItem>
        </NavItems>
      </Navbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/boeing" element={<Boeing />} />
        <Route path="/nttdata" element={<NTTDATA />} />
        <Route path="/uta" element={<UTA />} />
      </Routes>
      <Logo>gholder</Logo>

      {/* Hidden SEO-optimized text */}
      <div style={{ display: "none" }}>
        Gabriel Holder is a university student software developer with
        experience in TypeScript, React, and JavaScript. Holder was part of the
        team that developed "CodeKids" a project that tied for the People's
        Choice award at the 2024 Virginia Tech Undergraduate Research in
        Computer Science competition. He's interned with multiple government
        contractors as a student at Virginia Tech, he combines full-stack
        development with leadership experience.
      </div>
    </Router>
  );
}

const Home = () => (
  <Container>
    <Spline scene="https://prod.spline.design/WU2irVw9VyJ1X97g/scene.splinecode" />
  </Container>
);

const Boeing = () => (
  <Container>
    <Spline scene="https://prod.spline.design/p0HJlTnE-FgqihVl/scene.splinecode" />
  </Container>
);

const NTTDATA = () => (
  <Container>
    <Spline scene="https://prod.spline.design/3x-2Z1CLob7WXNr2/scene.splinecode" />
  </Container>
);

const UTA = () => (
  <Container>
    <Spline scene="https://prod.spline.design/xcaEoWHmaZZXqe7Z/scene.splinecode" />
  </Container>
);
